<template>
  <div>
    <el-form :model="form_data" :rules="formRules" :ref="form_name" :disabled="formDisabled" size="mini"
             label-position="right" label-width="100px">
      <div style="display: flex;align-items: center;justify-content:space-between;height: 60px;margin-bottom: 60px">

        <div style="display: flex;align-items: center;margin-left: 48px">
          <el-avatar :src="form_data.avatar"></el-avatar>
          <div style="margin-left: 12px;font-size: 16px;font-weight: bold">{{ form_data.nick_name }}</div>
        </div>
        <div style="display: flex;align-items: flex-end;justify-content: center;flex-direction: column">
          <div style="font-size: 16px;font-weight: bold;margin-bottom:12px;color: #409EFF">会员编号：{{ form_data.user_no }}</div>
          <div>{{ elTimeStampFormat(form_data.created_at) }}</div>
        </div>
      </div>
      <el-row>
        <el-col :span="8">
          <el-form-item label="是否启用:" prop="discount_enable">
            <el-switch v-model="form_data.discount_enable"></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="剩余次数:" prop="discount_times">
            <el-input-number v-model="form_data.discount_times"></el-input-number>
          </el-form-item>
        </el-col>
        </el-row>
        <el-row>
        <!--计算公式 实付 加、减、乘、除 基数-->
        <el-col :span="8">
          <el-form-item label="计算方式:" prop="discount_operate">
            <el-select v-model="form_data.discount_operate" >
              <el-option v-for="item in params.discount_operate" :key="item.key" :label="item.value" :value="item.key"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="计算基数:" prop="discount_ratio">
            <el-input-number v-model="form_data.discount_ratio"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="备注1:" prop="remark_1">
        <el-input v-model="form_data.remark_1"></el-input>
      </el-form-item>
      <el-form-item label="备注2:" prop="remark_2">
        <el-input v-model="form_data.remark_2"></el-input>
      </el-form-item>
      <el-form-item label="备注3:" prop="remark_3">
        <el-input v-model="form_data.remark_3"></el-input>
      </el-form-item>
      <el-form-item label="会员标签:" prop="tag">
        <el-input v-model="form_data.tag"></el-input>
      </el-form-item>
    </el-form>
    <div style="text-align:right;">
      <el-button v-if="action!=='view'" type="primary" @click="submitForm(form_name)" size="mini">提交编辑</el-button>
      <el-button @click="onCloseDialog" size="mini">关闭</el-button>
    </div>
  </div>
</template>

<script>
import {getDetail, updateInfo} from "/src/api/vstar/user"
import {getParams} from "/src/api/vstar/param"
import {formatTimestamp} from "@/utils/time";
const param_list = ['discount_operate']
export default {
  name: "user-detail",
  props: {
    action: {
      value: String,
      default: 'add'
    },
    rowId: {
      value: String,
    },
  },
  data() {
    return {
      form_name: 'user_detail',
      form_data: {},
      formRules: {
        remark_1: [{min: 1, max: 30, message: '备注输入长度为1-30个字符',}],
        remark_2: [{min: 1, max: 30, message: '备注输入长度为1-30个字符',}],
        remark_3: [{min: 1, max: 30, message: '备注输入长度为1-30个字符',}],
      },

      params: {},
      params_map: {},
      params_map_reverse: {},
    }
  },
  computed:{
    formDisabled(){
      return this.action === 'view' || !this.action;
    }
  },
  created() {
    this.initPage()
  },
  methods: {
    initPage() {
      this.initParams().then(()=>{
        if(this.rowId>0){
          getDetail(this.rowId).then(resp=>{
            this.form_data = resp.data
          })
        }
      })
    },
    initParams() {
      return new Promise((resolve, reject) => {
        getParams(param_list.join(), 'en').then(resp => {
          this.params = resp.data;
          console.log(this.params)

          let tmp_params_map = {}
          let tmp_params_map_pure = {}
          let tmp_params_map_reverse = {}

          let p_keys = Object.keys(this.params)
          p_keys.forEach(pl => {
            console.log('pl:', pl)
            let tmp_map = {}
            let tmp_map_pure = {}
            let tmp_map_reverse = {}
            this.params[pl].forEach(pm => {
              tmp_map_pure[pm.key] = {value: pm.value, color: pm.color}
              tmp_map_reverse[pm.value] = pm.key
            })
            tmp_params_map[pl] = tmp_map
            tmp_params_map_pure[pl] = tmp_map_pure
            tmp_params_map_reverse[pl] = tmp_map_reverse
          })
          this.params_map = tmp_params_map
          this.params_map_pure = tmp_params_map_pure
          this.params_map_reverse = tmp_params_map_reverse
          console.log('params_map:', this.params_map)
          console.log('params_map_reverse:', this.params_map_reverse)
          resolve(resp)
        }).catch((err) => {
          if (err) {
            console.error(err)
          }
          reject(err)
        })
      })
    },
    submitForm(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          console.log('this.form_data',this.form_data)
          updateInfo(this.form_data).then(()=>{
            this.$emit('onTellDialogClose')
          })
        }
      })
    },
    onCloseDialog(){
      this.$emit("onTellDialogClose")
    },
    elTimeStampFormat(val){
      return formatTimestamp(val)
    },
  }
}
</script>

<style scoped>
</style>
